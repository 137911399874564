import React from "react"

import "./pick_up.scss"

export const PickUp = () => (
  <div className="pick-up-delivery">
    <p>Curbside pick up and delivery available.</p>
    <p>Call us for more information!</p>
    <a href="https://slicelife.com/restaurants/ny/mineola/11501/eric-s-italian-bistro/menu?utm_campaign=order_now_button&amp;utm_medium=referral&amp;utm_source=">
      <img
        src="https://slicelife.com/images/order-now-horizontal.png"
        style={{ width: "100%", height: "auto", display: "block" }}
        alt="Slice Life"
      />
    </a>
  </div>
)
