import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./information.scss"

export const Information = () => {
  const {
    site: {
      siteMetadata: { information },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            information {
              address
              phone
              hours {
                info
              }
            }
          }
        }
      }
    `
  )
  return (
    <div className="information">
      <div className="information__address">
        <a
          href="http://maps.google.com/maps?q=70 E. Old Country Rd.Mineola,+NY+11501&amp;hl=en&amp;hnear=149+Mineola+Blvd,+Mineola,+New+York+11501&amp;gl=us&amp;t=m&amp;view=map&amp;z=30"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/layout/map.jpeg" alt="Eric's Bistro Directions" />
        </a>
        <h3>Address:</h3>
        <address>{information.address}</address>
      </div>
      <div className="information__phone">
        <h3>Phone Number:</h3>
        <p>{information.phone}</p>
      </div>
      <div className="information__hours">
        <h3>Dinner Hours:</h3>
        <ul>
          {information.hours.map(({ info }, index) => (
            <li key={`hour-${index}`}>{info}</li>
          ))}
        </ul>
      </div>
      <div className="information__hours">
        <h3>Scan and save our information!:</h3>
        <img src="/EricsBistro-QR-vcard.png" alt="Contact information QR Code" style={{ width: '100%', height: 'auto'}}/>
      </div>
    </div>
  )
}
