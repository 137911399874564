import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Information } from '../components/information'
// import { PickUp } from '../components/pick_up'
// import { HolidayAd } from '../components/holiday_ad'
import { Helmet } from 'react-helmet'

import './styles.scss'
import { PickUp } from '../components/pick_up'
import { OpenTable } from '../components/open_table'
export const Layout = ({ children, sidebar = true }) => {
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            navigation {
              name
              link
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eric's Italian Bistro – Modern & Italian Cuisine</title>
        <meta name="description" content="A family-run Italian restaurant that offers personal warm service that strives to provide an unforgettable experience." />
      </Helmet>
      <div className={`layout`}>
        <header className="layout__header">
          <figure className="layout-header__figure">
            <img src="/layout/EricsItalianBistro_header.png" alt="Eric's Italian Bistro – Modern & Classic Italian Cuisine" />
            <figcaption>
              <h1>Eric's Italian Bistro</h1>
              <h5>Modern & Classic Italian Cuisine</h5>
            </figcaption>
          </figure>
          <button className="layout-header__bookmark">
            <img src="/layout/bookmark_icon.png" alt="Bookmark our page!" />
          </button>
        </header>
        <nav className="layout__nav">
          <input id="layout-nav__trigger" className="layout-nav__trigger" type="checkbox" />
          <label htmlFor="layout-nav__trigger" className="layout-nav__burger-menu">
            <span className="open">☰</span>
            <span className="close">×</span>
          </label>
          <ul className="layout-nav__list">
            {siteMetadata.navigation.map(({ name, link }, index) => <li key={`nav-item-${index}`}>
              <Link to={link} title={name} activeClassName={'active'}>{name}</Link>
            </li>)}
          </ul>
        </nav>
        <main className={`layout__body${sidebar ? '' : ' layout__body--no-sidebar'}`}>
          <article className="layout-body__main">
            {children}
          </article>
          {sidebar && <aside className="layout-body__sidebar">
            <PickUp />
            <OpenTable />
            <Information />
          </aside>}
        </main>

      </div>
      <footer className="footer">
        <nav className="footer__nav">
          <ul>
            {siteMetadata.navigation.map(({ name, link }, index) => <li key={`footer-nav-item-${index}`}>
              <Link to={link} title={name} activeClassName={'active'}>{name}</Link>
            </li>)}
          </ul>
          <p className="footer__copyright">
            &copy;2021 Eric's Italian Bistro.
          </p>
        </nav>

      </footer>
    </>
  )
}
